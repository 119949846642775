@use '../../assets/css/mixins' as *;
@use '../../assets/css/variables' as *;

.anchorList {
  @include clean-list;
  display: flex;
  margin-bottom: 2em;

  li {
    text-transform: uppercase;
    + li {
      margin-left: 1em;
    }

    a {
      font-weight: 600;
      font-size: 14px;
      color: $primary;
      text-decoration: underline;
    }
  }
}
