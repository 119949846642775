@use "../../assets/css/_variables.scss" as *;
@use "../../assets/css/_mixins.scss" as *;
@use "../../assets/css/vendor/vendor_utils" as b;

.filterTitle {
  h2 {
    // @include block-heading;
    // @include b.desktop {
    //   border-right: 1px solid $secondary;
    // }
    // @include b.mobile {
    //   font-size: 18px;
    //   font-weight: bold;
    //   margin: 0.5em 0;
    // }
  }
}

.column:global(.column) {
  display: flex;
  align-items: center;
}

.filterLink {
  text-align: center;

  a {
    // @include reset-link($secondary);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
  }
}
