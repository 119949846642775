@use "../../assets/css/variables" as *;
@use "../../assets/css/mixins" as *;
@use "../../assets/css/vendor/vendor_utils" as *;

.sectionData {
  padding-top: 0em;
  padding-bottom: 0em;
}

.infoProfile .imageProfile {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 1.5rem !important;
}

.column_profile {
  padding: 0rem !important;
  margin: auto !important;
}

.column_image {
  text-align: center;
}

.image_reservation {
  border-radius: 5px !important;
}

.infoUserMeta {
  display: flex;
  flex-flow: column wrap;
  margin-left: 30px;

  h2 {
    font-weight: bold;
    font-size: 24px;
  }
}

.headerSection {
  h2 {
    font-weight: bold;
    font-size: 24px;
    // @include mobile {
    //   font-size: 18px;
    // }
  }
}

.columnsFavorite {
  justify-content: center;
  align-items: center;
}

.imageFavorite {
  object-fit: cover;
  width: 100px;
  height: 100px;
  padding: 0px !important ;
}

.editProfile {
  display: flex !important;
  justify-content: flex-end !important;

  button {
    font-weight: bold;
  }
}

.columnData {
  // @include mobile {
  //   padding-left: 0em;
  //   padding-right: 0em;
  // }

  // @include desktop {
  //   border-left: 2px solid $gray-ui;
  // }
}

.columnCard {
  // @include desktop {
  //   margin-left: 1.5em;
  // }
}

.icon_delete_favorite {
  font-size: 40px;
}

.input_file_photo {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file_input {
  justify-content: center !important;
}

.label_signup {
  display: block;
  position: relative;
  width: 6rem;
  height: 6rem;
  border-radius: 5rem;
  background-color: #cfcfc6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-out;
  margin-bottom: 1rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.file_name {
  position: absolute;
  bottom: -35px;
  left: 10px;
  font-size: 0.85rem;
  color: #555;
}

.columnCard {
  margin-bottom: 1rem;
}

.card {
  // background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.cardContent {
  display: flex;
  align-items: center;
}

.columnImage {
  text-align: center;

  .imageReservation {
    width: 170px;
    height: 170px;
    border-radius: 8px;
    object-fit: cover;
  }
}

.columnInfo {
  .edificeName {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-decoration: underline;
  }

  .infoList {
    list-style: none;
    padding: 0;
    margin: 0;

    .infoItem {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      margin-bottom: 0.3rem;

      .icon {
        margin-right: 0.5rem;
        color: #3489aa;
      }
    }
  }
}

.columnButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: flex-end;

  .reviewButton {
    background-color: transparent;
    color: #fd7e14;
    border: 2px solid #fd7e14;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #fd7e14;
      color: #fff;
    }
  }
}
