@use "../../assets/css/variables" as *;
@use "../../assets/css/mixins" as *;
@use "../../assets/css/vendor/vendor_utils" as b;

.calendarCardContent {

  // @include b.desktop {
  //   margin-top: 2.5em;
  //   margin-bottom: 3em;
  // }
  svg {
    color: $secondary;
  }

  .hiddenSelect {
    visibility: hidden;
  }
}

.subtextSection {
  color: $primary;
  font-weight: bold;
  font-size: 24px;
  margin-left: 1em;

  // @include b.mobile {
  //   font-size: 18px;
  //   margin-left: 0em;
  // }
  // @include b.tablet {
  //   margin-left: 0em;
  // }
}

.calendarSection {
  h4 {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 0.5em;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 0.5em;
    color: $gray-ui;
  }

  .boxDatePicker {
    padding-left: 0em;
    padding-right: 0em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .boxCustomRadioButtonGroup {
    padding-left: 0em;
    padding-right: 0em;
  }
}

.controlCalendar {
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    border: none;
    background: transparent;
  }
}

.calendarContent {
  display: flex;

  div {
    padding-bottom: 0.2em;
    padding-top: 0.2em;
    text-align: center;

    h3 {
      color: $secondary;
      font-weight: bold;
      margin-bottom: 0.5em;
    }

    .arrowSelect {
      color: $primary;
      font-size: 24px;
    }

    .is-fullwidth {
      // @include b.desktop {
      //   padding-left: 1.5em;
      //   padding-right: 1.5em;
      // }
      // @include b.mobile {
      //   padding-top: 0em;
      //   padding-left: 1.5em;
      //   padding-right: 1.5em;
      // }
    }
  }

  .arrowLeft,
  .arrowRight {
    border: none;
    background: transparent;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.5;
      border: none;
    }

    svg {
      font-size: 36px;
    }
  }
}

.reservationCard {
  margin-bottom: 1.5em;
  // @include b.desktop {
  //   margin-bottom: 2.5em;
  // }

  background-color: white !important;
  border: 1px solid #3b3b3b;
}

.summary {
  h4 {
    font-weight: bold;
    margin-bottom: 2em;
  }

  ul {
    li {
      +li {
        margin-top: 0.5em;
        display: flex;
        align-items: center;
      }

      strong,
      span {
        margin-left: 1em;
      }
    }
  }

  >div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}