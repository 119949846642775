@use "../../assets/css/variables" as v;
@use "../../assets/css/mixins" as *;
@use "../../assets/css/vendor/vendor_utils" as *;

.nameSpace {
  span {
    color: v.$gray-icon;
    font-size: 14px;
  }

  h1 {
    font-weight: bold;
    font-size: 32px;
    position: relative;
    padding-bottom: 0px;
    // @include mobile {
    //   font-size: 24px;
    // }
  }

  padding-bottom: 0px;
}

.detailActions {
  display: flex;
  align-items: center;
}

.detailColumn {
  padding-bottom: 0px;
  padding-top: 1em;
}

.detailFavorite {
  transition: all 0.3s ease-in-out;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  color: v.$gray-icon;
  font-size: 20px;

  // @include mobile {
  //   justify-content: flex-end;
  // }
  // @include desktop {
  //   svg {
  //     position: absolute;
  //     left: 6em;
  //   }
  // }
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  &:active {
    color: v.$primary;
    opacity: 0.7;
  }
}

.detailButton {
  display: flex;
  justify-content: flex-end;
  padding-top: 0px;
  padding-bottom: 0px;
  // @include tablet {
  //   justify-content: flex-end;
  // }
  // @include mobile {
  //   justify-content: center;
  //   margin-bottom: 0em;
  // }
}

.detailTextButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;

  // @include mobile {
  //   font-size: 14px;
  // }
}

.detailText {
  // @include desktop {
  //   margin-top: 2em;
  // }
  p {
    font-weight: 600;
    font-size: 15px;
  }
}

.subtextSection {
  color: v.$secondary;
  font-weight: bold;
  font-size: 24px;
  // @include mobile {
  //   font-size: 18px;
  // }
}

.bookButtonContainer:global(.column) {
  display: grid;
  place-items: center;
}

.button.is-primary {
  background-color: v.$primary !important;
  color: white !important;

  &:hover {
    background-color: lighten(v.$primary, 8%) !important;
  }
}
