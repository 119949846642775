@use "../../assets/css/variables" as *;
@use "../../assets/css/vendor/vendor_utils" as b;

.searchResults {
  h2 {
    color: $gray-ui;
    font-weight: bold;
    margin-right: 1em;
  }

  :global(.column) {
    display: flex;
    align-items: center;

    small {
      color: $gray-ui;
      font-size: 12px;
      margin-right: 0.5rem;
      font-weight: normal;
    }

    label {
      display: flex;
      align-items: center;
    }

    // @include b.desktop {
    //   justify-content: flex-end;
    // }
  }
}

.searchGroup {
  width: 100%;
}