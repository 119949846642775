body {
  color: $text;
}

.container {
  > .columns {
    width: 100%;
  }
}

button {
  &.navbar-link {
    background-color: inherit;
    border: medium none;
    font-size: 1em;
  }
}

h1 {
  font-weight: bold;
  font-size: 32px;
  position: relative;
  padding-bottom: 1rem;

  @include mobile {
    font-size: 24px;
  }
}
