.BrainhubCarouselItem {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .debug-number {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    font-size: 2em;
    text-shadow: 0px 0px 9px white;
  }
}
.BrainhubCarouselItem.BrainhubCarouselItem--clickable {
  cursor: pointer;
}
.BrainhubCarousel__container {
  width: 100%;
  overflow: hidden;
}
.BrainhubCarousel {
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  .BrainhubCarousel__trackContainer {
    overflow: hidden;
    @include desktop {
      // margin-left: -40px;
      // margin-right: -40px;
    }

    .BrainhubCarousel__track {
      display: flex;
      overflow: hidden;
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .BrainhubCarousel__track.BrainhubCarousel__track--transition {
      transition: transform;
    }
  }
}
.BrainhubCarousel.BrainhubCarousel--isRTL {
  direction: rtl;
  .BrainhubCarousel__trackContainer {
    .BrainhubCarousel__track {
      direction: rtl;
    }
  }
}
.BrainhubCarousel__arrows {
  cursor: pointer;
  background-color: transparent;
  z-index: 1;
  position: absolute;
  padding: 0;
  border: none;
  outline: 0;
  font-size: 0;
  line-height: 0;
  width: 0;

  &::after {
    content: "";
    background-color: white;
    border-radius: 50%;
    display: block;
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(black, 0.1);
  }

  span {
    border-style: solid;
    border-color: $primary;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    border-width: 3px 3px 0 0;
    padding: 5px;
    transition: 0.3s;
    font-size: 0;
  }
  &:hover {
    &:after {
      background-color: lighten($color: $primary, $amount: 8);
    }

    span {
      border-style: solid;
      border-color: #fff;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      border-width: 3px 3px 0 0;
      padding: 5px;
      transition: 0.3s;
      font-size: 0;
    }
    &:enabled {
      &:after {
        background-color: lighten($color: $primary, $amount: 8);
      }
      span {
        border-color: #fff;
      }
    }
  }
  &:disabled {
    span {
      border-color: $gray-icon;
    }
    &:hover {
      &:after {
        background-color: white;
      }
    }
  }
}
.BrainhubCarousel__dots {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  .BrainhubCarousel__dot {
    cursor: pointer;
    opacity: 0.5;
    outline: 0;
    padding: 10px;
    border: none;
    font-size: 0;
    -webkit-appearance: none;
    &:hover {
      opacity: 1;
    }
    &:before {
      content: "";
      background: #000;
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      padding: 0;
      border: none;
    }
  }
  .BrainhubCarousel__dot.BrainhubCarousel__dot--selected {
    opacity: 1 !important;
  }
  .BrainhubCarousel__thumbnail {
    opacity: 0.5;
    cursor: pointer;
    outline: 0;
    padding: 10px;
    border: none;
    font-size: 0;
    &:hover {
      opacity: 1;
    }
  }
  .BrainhubCarousel__thumbnail.BrainhubCarousel__thumbnail--selected {
    opacity: 1 !important;
  }
}
.BrainhubCarousel__dots.BrainhubCarousel__dots--isRTL {
  direction: rtl;
}
.BrainhubCarousel__thumbnail[type="button"] {
  -webkit-appearance: none;
}
.BrainhubCarousel--isRTL {
  + {
    .BrainhubCarousel__dots {
      direction: rtl;
    }
  }
  .BrainhubCarousel__arrowLeft {
    span {
      transform: translate(-50%, -50%) rotate(45deg);
      margin-left: -2.45px;
    }
  }
  .BrainhubCarousel__custom-arrowLeft {
    span {
      transform: rotate(180deg);
    }
  }
  .BrainhubCarousel__arrowRight {
    span {
      transform: translate(-50%, -50%) rotate(-135deg);
      margin-left: 2.45px;
    }
  }
  .BrainhubCarousel__custom-arrowRight {
    span {
      transform: rotate(-180deg);
    }
  }
  .BrainhubCarousel__arrows {
    &:hover {
      span {
        margin: 0;
      }
    }
  }
}
.BrainhubCarousel__arrowLeft {
  span {
    transform: translate(100%, -50%) rotate(-135deg);
    margin-left: 2.45px;
  }
}
.BrainhubCarousel__arrowRight {
  right: 40px;

  span {
    transform: translate(100%, -50%) rotate(45deg);
    margin-left: -2.45px;
  }
}
.BrainhubCarousel__arrow--disable {
  pointer-events: none;
}
