@use "../../assets/css/mixins" as *;
@use "../../assets/css/variables" as *;

.active {
  border-color: $primary !important;
}

.tabbedFilter {
  @include clean-list;
  display: flex;

  li {
    + li {
      margin-top: 0;
      margin-left: 0.5em;
    }

    button {
      font-weight: 600;
      font-size: 14px !important;
      padding: 0.1em 0.4em;
      height: 24px;

      &:not(:hover) {
        border: 1px solid transparent;
      }
    }
  }
}
