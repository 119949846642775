@use '../../assets/css/variables' as *;

.loadingBlock {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  min-height: 60px;
  border: 1px solid $gray-icon;
  border-radius: 4px;
  width: 100%;
}

.loadingBlock > div {
  color: $primary !important;
}

.loadingMsg {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
}
