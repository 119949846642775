.modalContent {
  > div {
    display: flex;
    justify-content: center;
  }
}

.rankinButton {
  :hover {
    cursor: pointer;
  }
}
