.container {
  position: relative;
}

.icon_eye {
  position: absolute;
  right: 1em;
  font-size: 125%;
  top: 50%;
  transform: translateY(-50%);
}
