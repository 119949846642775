@use '../../assets/css/mixins';
@use "../../assets/css/variables" as v;

.blueprint {
  background-color: v.$gray;
  border: 1px solid v.$gray;
  width: 100%;
  min-height: 540px;
}

.zoomInner {
  position: relative;
  display: flex;
  flex-direction: column;

  > svg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 10;
  }
}
