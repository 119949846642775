@use '../../assets/css/variables' as *;

.svg {
  z-index: 1;

  path,
  polygon,
  rect,
  circle {
    fill: rgba($color: $primary, $alpha: 0.5);
    stroke: $primary;
    stroke-width: 2px;
    cursor: pointer;
    transition: fill 0.3s ease;

    &:hover {
      fill: rgba($color: $primary, $alpha: 1);
    }

    &.marked {
      fill: rgba($color: $secondary, $alpha: 0.85);
      stroke: $secondary;
      stroke-width: 4px;
    }

    &.occupy {
      fill: rgba($color: red, $alpha: 0.85);
      stroke: $secondary;
      stroke-width: 4px;
      pointer-events: none;
    }
  }
}

.tooltip {
  background-color: black;
  border-radius: 8px;

  p {
    color: white;
    font-size: 20px;
    text-align: center;
    padding: 0 0.5em;
    line-height: 60px;
    margin: 0;
  }
}
