.fieldset {
  margin-top: 1em;
  legend {
    font-size: 12px;
    margin-bottom: 1em;
  }

  &__inputRange {
    margin-top: 19px !important;

    & div div:first-child {
      background-color: #d3771f !important;
    }

    & div div:last-child {
      background-color: #d3771f !important;
      color: #d3771f !important;
      border: #d3771f !important;
    }
  }
}
