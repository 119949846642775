@use "../../assets/css/variables" as *;
@use "../../assets/css/mixins" as *;
@use "../../assets/css/vendor/vendor_utils" as b;

.venue {
  height: 0;
  padding-bottom: 100%;
  position: relative;
  border-radius: 4px;
  overflow: hidden;

  // @include b.desktop {
  //   width: 165px;
  // }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }
}

.description {
  padding-left: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  // h3 {
  //   @include b.desktop {
  //     @include heading(2);
  //   }
  // }

  div:first-child {

    >span,
    >small {
      display: block;
      margin-top: 0.5em;
    }
  }

  small {
    color: $gray-ui;
  }
}

.actions {
  margin-top: 0.5em;
  display: flex;
  justify-content: space-between;

  >div {
    flex: 0 1 auto;
    display: flex;
    align-items: center;

    &:first-child {
      >i+i {
        margin-left: 0.5em;
      }
    }

    &:last-child {
      >*+* {
        margin-left: 1em;
      }
    }
  }
}