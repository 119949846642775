.footerCnt {
  display: flex;
  background-color: #f8f8f8;
  width: 100%;
  height: 80px;
  text-align: center;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
}

.footerCnt > p > span {
  color: #fc5b62 !important;
}
