.gridGallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;

  button {
    cursor: pointer;
    border: medium none;
    padding: 0;
    border-radius: 4px;
    overflow: hidden;
  }

  figure {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
  }
}
