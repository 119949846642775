@use "../../assets/css/variables" as *;
@use "../../assets/css/mixins" as *;
@use "../../assets/css/vendor/vendor_utils" as b;

.boxPhoto {
  // @include b.mobile {
  //   div {
  //     width: 3em !important;
  //   }
  // }
}

.descriptionHeader,
.descriptionHeaderWithPhoto {
  h1 {
    font-weight: bold;
    font-size: 28px;
  }

  h2 {
    color: $secondary;
    font-weight: bold;
    font-size: 24px;
    // @include b.mobile {
    //   font-size: 18px;
    // }
  }

  h3 {
    font-weight: bold;
    font-size: 16px;
    text-decoration: underline;
  }

  h4 {
    padding-top: 10px;
    font-weight: 100;
    font-size: 14px;
  }

  p {
    font-size: 12px;
    color: $gray-ui;
  }
}

.descriptionHeaderWithPhoto {
  display: flex;

  h3 {
    margin-left: 1em;
  }
}

.boxDataColumn,
boxDataColumnWithPhoto {
  padding-top: 0em;
}

.boxDataColumnWithPhoto {
  // @include b.mobile {
  // }
  // @include b.desktop {
  //   position: absolute;
  //   left: 12.5em;
  //   top: 3em;
  // }
}

.columnIsNotMenu {
  padding-top: 0px;
  padding-bottom: 0px;
  // @include b.desktop {
  //   padding-left: 1.5em;
  // }
  padding: 0em 0em 0em 0em !important;
}

.columnIsMenu {
  padding: 0em 0em 1em 0em;
}

.hasNotPaddingBottom {
  padding: 0em;
}

.formPartHeader {
  display: flex;
  align-items: center;

  svg {
    font-size: 20px;
    color: $gray-icon;
    margin-right: 1em;
    // @include b.mobile {
    //   margin-right: 0.5em;
    //   font-size: 20px;
    // }
  }

  h3 {
    color: #3b3b3b;
    font-size: 14px;
  }

  h4 {
    font-weight: bold;
    font-size: 16px;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.formPartBody {
  padding-top: 0px;
  padding-left: 4em;

  p {
    font-weight: 500;
    font-size: 14px;
  }

  // @include b.mobile {
  //   padding-left: 3.2em;
  // }
  // @include b.tablet {
  //   padding-left: 3.4em;
  // }
  // @include b.desktop {
  //   padding-left: 4em;
  // }
}

.formPartServices {
  display: flex;
  width: 100%;
  flex-direction: row;

  div {
    padding-right: 2em;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
      display: flex;
      align-items: center;
      font-size: 14px;
    }

    svg {
      font-size: 22px;
      margin-right: 1em;
      color: $gray-icon;
    }
  }

  // @include b.mobile {
  //   flex-direction: column;
  //   text-align: left;
  //   div {
  //     padding-right: 0em;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     svg {
  //       font-size: 28px;
  //     }
  //     p {
  //       width: 100%;
  //       font-size: 14px;
  //       text-align: left;
  //     }
  //   }
  // }
}

.descriptionButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  // @include b.mobile {
  //   h4 {
  //     margin-bottom: 5px;
  //   }
  //   button {
  //     display: flex;
  //     width: 100%;
  //     margin-top: 5px;
  //   }
  // }
  button {
    font-size: 18px;
    font-weight: bold;
  }
}

.menuButton {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
}

.activeMenuItem {
  font-weight: bold;
  background-color: #3489aa;
  border-radius: 7px;
}
.activeMenuItemButton {
  color: white !important;
  font-weight: bold;
}
