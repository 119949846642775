.mainMenu {
  &:global(.column) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__bar {
    margin-left: 0;
    margin-right: 0 !important;
  }

  &__nav {
    margin: 0 auto;

    > * + * {
      margin-left: 0.8em;
    }

    a {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.userMenu {
  display: flex !important;
  box-shadow: none !important;

  &:global(.column) {
    display: flex;
    align-items: center;
  }

  &__container {
    margin-left: auto;
    margin-right: 0;
  }

  svg {
    width: 28px;
    height: 28px;
    margin-right: 0.5em;
  }

  span {
    font-size: 14px;
  }

  span,
  a {
    font-weight: 600;
  }
}

.search {
  padding: 0;
  width: 40px;
  height: 40px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.hasSvg {
  display: flex !important;
  box-shadow: none !important;
  justify-content: center;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
  }
}

.avatarProfile {
  padding-right: 5px;
}

.profile {
  padding-left: 10px;
}

.imgage_logo {
  max-height: 2.2rem !important;
}

.iconLink {
}
