@use "../../assets/css/variables" as *;
@use "../../assets/css/vendor/vendor_utils" as b;

.boxDurationRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 0em;
  padding-right: 0em;
  justify-content: flex-start;
  // @include b.mobile {
  //   justify-content: space-between;
  // }
  // @include b.tablet {
  //   justify-content: flex-start;
  // }

  button {
    border-color: $gray-icon;
    margin-right: 0.5em;
    margin-bottom: 0.5em;

    // @include b.mobile {
    //   margin-right: 0em;
    // }
    // @include b.desktop {
    //   padding-left: 0.5em;
    //   padding-right: 0.5em;
    // }
    &:hover {
      background: $secondary;
      color: white !important;
    }

    &:disabled {
      opacity: none !important;
      background: transparent;
      border-color: $gray-ui;
      color: $gray-ui !important;

      &:hover {
        color: $gray-ui;
        opacity: none !important;
      }
    }
  }
}

.boxDurationColumn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // @include b.desktop {
  //   padding-left: 0em;
  //   padding-right: 0em;
  // }

  button {
    margin-bottom: 0.5em;
    border-color: $gray-icon;
    width: 100%;

    &:hover {
      background: $secondary;
      color: white !important;
    }

    &:disabled {
      opacity: none !important;
      background: transparent;
      border-color: $gray-ui;
      color: $gray-ui !important;

      &:hover {
        color: $gray-ui;
        opacity: none !important;
      }
    }
  }
}

.buttonSelected {
  background: $secondary;
  color: white !important;
}