@use "../../assets/css/variables" as *;

.cardRanking {
  font-size: 18px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;

  i {
    display: flex;
    align-items: center;
  }

  svg {
    color: rgba($gray-ui, 0.4);
  }

  span {
    color: $gray-ui;
    margin: 0 0 0 0.2em;
    font-size: 14px;
  }
}

.activeStar {
  svg {
    color: $secondary;
  }
}
