@use "../../assets/css/variables" as *;

.datePicker {
  padding-left: 0em;
  padding-right: 0em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  input {
    border-color: $gray-icon;
    color: $secondary;
    background: #fff
      url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
      90% 50% no-repeat;
    &:hover {
      cursor: pointer;
    }
  }
}
.modalPicker {
  @media (max-width: 375px) {
    position: absolute;
    left: -27px !important;
  }
}
