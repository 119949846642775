@import "@rakuten-rex/switch/Switch/sass/_styles.mixin.scss";
$--rex-switch-theme: $primary;

.rex-switch {
  @include rex-switch();
  display: flex;
  align-items: center;

  &-container {
    width: 32px;
    height: 18px;

    input[type="checkbox"] {
      + .rex-switch-knob {
        &:before {
          width: 14px;
          height: 14px;
        }
      }

      &:checked {
        + .rex-switch-knob:before {
          transform: translateX(14px);
        }
      }
    }
  }
}
