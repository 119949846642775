@use "../../assets/css/variables" as *;
@use "../../assets/css/vendor/vendor_utils" as b;

.image {
  width: 100%;
  height: 0;
  padding-bottom: 72.6%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 4px;

  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
  }
}

.details {
  padding: 1em 0;

  h3 {
    font-size: 16px;
    font-weight: bold;

    // @include b.desktop {
    //   font-size: 18px;
    // }
  }

  small {
    display: inline-block;
    color: $gray-ui;
    font-size: 12px;
    margin-top: 1em;

    // @include b.desktop {
    //   font-size: 14px;
    // }
  }
}