@use "./variables" as var;

@mixin reset-link($color: inherit) {
  color: $color;
  text-decoration: underline;
}

@mixin clean-list {
  list-style: none outside none !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
  padding: 0;
}

@mixin block-heading {
  color: var.$secondary;
  font-size: 24px;
  margin-bottom: 0;
  padding-right: 1em;
  width: 100%;
}

@mixin heading($type: 1) {
  font-style: normal;
  font-weight: bold;

  @if $type == 2 {
    font-size: 24px;
  }

  @if $type == 3 {
    font-size: 18px;
  }
}
