.checkField {
  label {
    input {
      margin-right: 0.6em;
    }

    span {
      font-size: 15px;
      font-weight: normal;
    }
  }
}
