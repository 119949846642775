.loader {
  border-color: $gray;
  border-right-color: $primary;
  border-top-color: $primary;

  &-medium {
    border-width: 3px;
    width: 24px;
    height: 24px;
  }

  &-big {
    border-width: 4px;
    width: 48px;
    height: 48px;
  }
}
