@use "../../assets/css/variables" as *;

.homeBooking {
  h1 {
    &:after {
      content: "";
      background-color: $primary;
      bottom: 0;
      left: 0;
      width: 50px;
      height: 3px;
      position: absolute;
    }
  }
}

.bookingForm {
  padding-top: 1em;

  :global(.column) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.bookingField {
  width: 100%;
  margin-bottom: 19px;

  select {
    width: 100%;
  }
}

.datePicker {
  width: 21.5vw;
  margin: 0;
  flex: 1;
  // max-width: 100%;
  clear: both;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
}

.datePickerActions {
  clear: both;
  padding: 0.5em;
  display: flex;
  flex-direction: column;

  button {
    font-size: 14px !important;
    font-weight: 600;

    + button {
      margin-top: 0.5em;
    }
  }
}

.textPosition {
  position: relative;
}

.formControl {
  .help {
    position: relative;
    top: 5px;
  }
}

.bookingSearch {
  width: 100%;
  position: relative;
  top: -19px;
  background-color: #d3771f;
  color: #fff;
}
