.is-rounded {
  &-icon {
    border-radius: 50%;
    display: grid;
    place-items: center;
  }
}

.card {
  background-color: $gray;
  border-radius: 8px;
  padding: 1em;
}

.block {
  &__heading {
    @include block-heading;
  }
}

.text {
  &-center {
    &--touch {
      @include touch {
        text-align: center;
      }
    }

    &--mobile {
      @include mobile {
        text-align: center;
      }
    }
  }
}

.field {
  &.is-action {
    display: flex;

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: medium none;
    }

    button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 0 0.5em;

      svg {
        width: 20px;
        height: 20px;
      }

      &:hover {
        background-color: $primary;
      }
    }
  }
}

.button,
button {
  &.is-svg {
    border: medium none;
    padding: 4px;
    background-color: transparent;
  }

  &.as-link {
    border: medium none;
    font-size: 1em;
    color: $primary;
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
  }
}

// lists

.clean-list {
  @include clean-list;
}

// fix

.o-h {
  overflow: hidden;
}

.inline-loader {
  display: inline-block !important;
}

//cards
.card {
  &-link {
    @include reset-link;
    font-size: 18px;
    font-weight: bold;
  }
}
