@use "../../assets/css/vendor/vendor_utils" as b;

.hero {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(black, 0.2);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  // @include b.desktop {
  //   &:global(.is-medium) {
  //     height: 408px;
  //   }

  //   &:global(.is-small) {
  //     height: 320px;
  //   }
  // }

  // @include b.mobile {
  //   &:global(.is-small) {
  //     height: 192px;
  //   }
  // }

  padding: 7em 0;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      width: 100%;
    }

    &:global(.container) {
      // @include b.desktop {
      //   width: 100%;
      // }

      // @include b.mobile {
      //   margin: 0 1em;
      // }
    }
  }
}
