@use "../../assets/css/variables" as v;
@use "../../assets/css/mixins" as *;
@use "../../assets/css/vendor/vendor_utils" as *;

.asideList {
  margin-top: 2.5em;

  h4 {
    margin-bottom: 1em;
    line-height: 22px;
    color: v.$secondary;

    // @include desktop {
    //   @include heading(3);
    // }
  }

  a {
    font-weight: bold;
    font-size: 15px;
    color: v.$text;
    text-decoration: underline;

    &:hover {
      color: v.$primary;
    }
  }

  small {
    display: block;
    font-size: 12px;
    margin-top: 0.5em;
    color: v.$gray-ui;
  }
}

.asideListItem {
  display: flex;

  +.asideListItem {
    margin-top: 0.8em;
  }

  figure {
    flex: 0 0 auto;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  div {
    flex: 1 1 auto;
  }
}

.sectionAside {
  height: 82vh;
}