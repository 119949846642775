.layoutContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Asegura que el contenedor ocupe toda la altura de la pantalla */
}

.mainContent {
  flex: 1; /* Hace que el contenido ocupe el espacio restante */
  padding: 20px; /* Opcional: añade algo de espacio alrededor del contenido */
}
