$datepicker__background-color: #f0f0f0;
$datepicker__border-color: #aeaeae;
$datepicker__highlighted-color: $primary;
$datepicker__muted-color: #ccc;
$datepicker__selected-color: $primary;
$datepicker__text-color: #000;
$datepicker__header-color: $secondary;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%);

$datepicker__border-radius: 0.3rem;
$datepicker__day-margin: 0.166rem;
$datepicker__font-size: 0.8rem;
$datepicker__font-family: $family-sans-serif;
$datepicker__item-size: 2rem;
$datepicker__margin: 1rem;
$datepicker__navigation-size: 0.45rem;
$datepicker__triangle-size: 8px;

@import "~react-datepicker/src/stylesheets/datepicker.scss";
