@import "../../assets/css/variables";
@import "../../assets/css/mixins";
@import "../../assets/css/vendor/bulma";

.filterCard {
  padding: 0.7em;
}

.homeListCard {
  display: block;
}

.cardImage {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;

  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.cardHeading {
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  @include mobile {
    a {
      font-size: 16px;
    }
  }
}

.cardText {
  span {
    color: $gray-ui;
    font-size: 14px;
  }

  p {
    font-weight: 600;
    font-size: 15px;
  }
}

.available {
  background-color: $primary;
  color: white;
  position: absolute;
  right: 0;
  top: 0;
  font-weight: bold;
  font-size: 14px;
  padding: 0 2em;
  border-radius: 0 0 0 2px;
  height: 28px;
  line-height: 28px;

  @include touch {
    font-size: 12px;
    padding: 0 0.5em;
    letter-spacing: 1px;
  }
}
