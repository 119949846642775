@use "../../assets/css/variables" as *;

.infoWindow {
  padding: 0.5em;
  a {
    color: $primary;
    text-decoration: underline;
    font-size: 16px;
    font-weight: bold;
  }

  p {
    color: $text;
    margin-top: 1em;
    font-size: 14px;
    font-weight: 500;
  }
}
