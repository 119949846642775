@use "../../assets/css/variables" as *;
@use "../../assets/css/vendor/vendor_utils" as b;

.commentCard {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  padding-left: 2em !important;
  padding-right: 2em !important;
  background-color: white !important;
  border: 1px solid #3b3b3b;
  // @include b.mobile {
  //   padding-left: 1em !important;
  //   padding-right: 1em !important;
  // }
}

.commentCardContent {
  padding: 0px;
}

.commentText {
  p {
    font-weight: 600;
    font-size: 15px;
    text-align: justify;
  }
}

.detailRanking {
  font-size: 18px;
  display: flex;
  align-items: center;

  i {
    display: flex;
    align-items: center;
  }

  svg {
    color: $gray-icon;
  }

  span {
    color: $gray-icon;
    margin-left: 0.2em;
    font-size: 14px;
  }
}

.activeStar {
  svg {
    color: $secondary;
  }
}

.commentValoration {
  padding-top: 0px;

  span {
    // @include b.mobile {
    //   font-size: 12px;
    //   .detailRanking {
    //     font-size: 12px;
    //   }
    // }
  }
}

.avatarCard {
  transition: all 0.3s ease-in-out;

  p {
    color: $secondary;
    font-weight: bold;
    margin-left: 1em;
    // @include b.mobile {
    //   margin-left: 0.5em;
    //   font-size: 14px;
    // }
  }

  .detailRanking {
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    &:active {
      opacity: 0.5;
    }
  }
}

.cardReactions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    border: medium none;

    &:hover {
      svg {
        color: $primary;
      }
    }

    svg {
      color: $gray-icon;
      font-size: 14px;
    }

    // @include b.mobile {
    //   svg {
    //     font-size: 20px;
    //   }
    // }
    // @include b.desktop {
    //   border: medium none;
    //   margin: 0.5em;
    //   svg {
    //     font-size: 26px;
    //   }
    // }
  }
}