@use "../../assets/css/variables" as *;

.svgIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-ui;

  &:global(.small) {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &:global(.medium) {
    svg {
      width: 22px;
      height: 22px;
    }
  }

  &:global(.large) {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &:global(.extra-large) {
    svg {
      width: 28px;
      height: 28px;
    }
  }
}
