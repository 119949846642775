$family-sans-serif: "Muli", sans-serif;
$text: #3b3b3b;
$navbar-item-color: $text;
$gray: #eaeaea;
$gray-icon: #c4c4c4;
$gray-ui: #999999;
$primary: #d3771f;
$secondary: #3489aa;
$dark: $gray-ui;
$input-arrow: $primary;
$input-hover-color: lighten($primary, 8);
$section-padding: 2rem 1.5rem;
$tabs-link-active-border-bottom-color: $primary;
$tabs-link-active-color: $primary;

// cards
$card-shadow: none;

// container
$fullhd: 1200px;
$widescreen: 960px;
$tablet: 768px;
