@use "../../assets/css/_variables.scss" as *;
@use "../../assets/css/_mixins.scss" as m;
@use "../../assets/css/vendor/vendor_utils" as *;

.carousel {
  :global(.BrainhubCarouselItem) {
    > div {
      width: 100%;
    }
  }
}

.filterCard {
  margin-inline: 10px;
}

.filterTitle {
  h2 {
    // @include m.block-heading;
    // @include desktop {
    //   border-right: 1px solid $secondary;
    // }
    // @include mobile {
    //   font-size: 18px;
    //   font-weight: bold;
    //   margin: 0.5em 0;
    // }
  }
}

.column {
  display: flex;
  align-items: center;
}

.filterLink {
  text-align: center;

  a {
    // @include m.reset-link($secondary);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
  }
}
