@use "../../assets/css/mixins" as m;
@use "../../assets/css/variables" as v;
@use "../../assets/css/vendor/vendor_utils" as b;

.item {
  +.item {
    margin-top: 1em;

    // @include b.mobile {
    //   border-top: 1px solid v.$gray;
    //   padding-top: 1em;
    // }
  }
}

.image {
  height: 0;
  overflow: hidden;
  position: relative;
  padding-bottom: 53.93%;
  width: 100%;
  border-radius: 8px;

  img {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }
}

.info {
  h3 {
    font-size: 24px;
    font-weight: bold;

    // @include b.mobile {
    //   font-size: 18px;
    // }
  }

  span {
    font-size: 18px;
    color: v.$gray-ui;

    // @include b.mobile {
    //   font-size: 16px;
    // }
  }

  p {
    margin-top: 2em;
    line-height: 20px;
    font-size: 16px;
  }

  a {
    // @include m.reset-link(v.$primary);
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;

    // @include b.desktop {
    //   margin-top: 1rem;
    // }
  }
}

.actions {
  display: flex;

  ul {
    // @include b.mobile {
    //   display: flex;
    //   width: 100%;
    //   align-items: center;
    //   justify-content: center;

    //   li + li {
    //     margin-left: 1em;
    //   }
    // }
  }

  // @include b.desktop {
  //   li {
  //     +li {
  //       margin-top: 1em;
  //     }
  //   }
  // }

  // @include b.touch {
  //   padding: 0.5em 0 0;
  // }

  a {
    border: medium none;
    padding: 0;
    padding: 4px;

    svg {
      width: 28px;
      height: 28px;
      color: v.$gray-icon;
    }

    &:hover {
      svg {
        color: v.$primary;
      }
    }
  }
}